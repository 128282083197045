/* eslint-disable @typescript-eslint/naming-convention */
export enum Icons {
    warningEmotionLessIcon = "warning-emotion-less-icon",
    accessOffSvg = "access-off-svg",
    accessOnSvg = "access-on-svg",
    appStoreIconSvg = "app-store-icon-svg",
    authenticationIconSvg = "authentication-icon-svg",
    authorizationLogoSvg = "authorization-logo-svg",
    buttonLoadingSvg = "button-loading-svg",
    calendarSvg = "calendar-svg",
    chartSvg = "chart-svg",
    checkedStepIconSvg = "checked-step-icon-svg",
    clearLightSvg = "clear-light-svg",
    xSvg = "x-svg",
    closeNotificationModalSvg = "close-notification-modal-svg",
    closeSvg = "close-svg",
    companyDefaultLogoSvg = "company-default-logo-svg",
    configurationInfoSvg = "configuration-info-svg",
    copyIconSvg = "copy-icon-svg",
    dashboardSvg = "dashboard-svg",
    defaultAvatarSvg = "default-avatar-svg",
    defaultGameImageSvg = "default-game-image-svg",
    defaultGameSvg = "default-game-svg",
    defaultImageIconSvg = "default-image-icon-svg",
    defaultProfileInfoAvatarSvg = "default-profile-info-avatar-svg",
    downArrowSvg = "down-arrow-svg",
    dragAndDropSvg = "drag-and-drop-svg",
    errorSvg = "error-svg",
    eyeOffSvg = "eye-off-svg",
    eyeOnSvg = "eye-on-svg",
    filterClearIconSvg = "filter-clear-icon-svg",
    filterIconSvg = "filter-icon-svg",
    filterV2Svg = "filter-v2-svg",
    folderSvg = "folder-svg",
    googlePlayIconSvg = "google-play-icon-svg",
    headerFilterSvg = "header-filter-svg",
    headerLogoTextSvg = "header-logo-text-svg",
    infoLargeIconSvg = "info-large-icon-svg",
    infoSvg = "info-svg",
    leftMenuB2bManagementSvg = "left-menu-b2b-management-svg",
    leftMenuCompanyManagementSvg = "left-menu-company-management-svg",
    leftMenuProviderManagementSvg = "left-menu-provider-management-svg",
    lineMenuSvg = "line-menu-svg",
    loaderSvg = "loader-svg",
    lockSvg = "lock-svg",
    menuSvg = "menu-svg",
    notificationsSvg = "notifications-svg",
    profileSvg = "profile-svg",
    qrAppStoreSvg = "qr-app-store-svg",
    qrGooglePlaySvg = "qr-google-play-svg",
    reportSvg = "report-svg",
    reverseArrowsSvg = "reverse-arrows-svg",
    rightArrowSvg = "right-arrow-svg",
    toggleMenuLeftArrowSvg = "toggle-menu-left-arrow-svg",
    toggleMenuRightArrowSvg = "toggle-menu-right-arrow-svg",
    upArrowSvg = "up-arrow-svg",
    USDSvg = "USD-svg",
    warningIconSvg = "warning-icon-svg",
    warningYellowIconSvg = "warning-yellow-icon-svg",
    activeStatusOnSvg = "active-status-on-svg",
    activeStatusOffSvg = "active-status-off-svg",
    searchSvg = "search-svg",
    tagsForGamesSvg = "tags-for-games-svg",
    aggAnalyticsSvg = "agg-analytics-svg",
    reverseSvg = "reverse-svg",
    infoIconSvg = "info-icon-svg",
    reportIconSvg = "report-icon-svg",
    standardReportWarningSvg = "standard-report-warning-svg",
    projectSvg = "project-svg",
    eagleSvg = "eagle-svg",
    monitoringSvg = "monitoring-svg",
    addMoreSvg = "add-more-svg",
    pageInfoSvg = "page-info-svg",
    helpSvg = "help-svg",
    metricsForAnalyticsSvg = "metrics-for-analytics-svg",
    uploadFileSvg = "upload-file-svg",
    defaultArticleImageSvg = "default-article-image-svg",
    xlsFileSvg = "xls-file-svg",
}
/* eslint-enable @typescript-eslint/naming-convention */

export interface IconProps {
    name: Icons;
}
