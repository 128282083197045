import dynamic from "next/dynamic";

import { IconProps, Icons } from "./icon.interface";

const AccessOffSvg = dynamic(() =>
    import("./components/access-off-svg.component").then(
        (icon) => icon.AccessOffSvg,
    ),
);
const WarningEmotionLessIcon = dynamic(() =>
    import("./components/warning-emotion-less-icon").then(
        (icon) => icon.WarningEmotionLessIcon,
    ),
);
const AccessOnSvg = dynamic(() =>
    import("./components/access-on-svg.component").then(
        (icon) => icon.AccessOnSvg,
    ),
);
const AppStoreIconSvg = dynamic(() =>
    import("./components/app-store-icon-svg.component").then(
        (icon) => icon.AppStoreIconSvg,
    ),
);
const AuthenticationIconSvg = dynamic(() =>
    import("./components/authentication-icon-svg.component").then(
        (icon) => icon.AuthenticationIconSvg,
    ),
);
const AuthorizationLogoSvg = dynamic(() =>
    import("./components/authorization-logo-svg.component").then(
        (icon) => icon.AuthorizationLogoSvg,
    ),
);
const ButtonLoadingSvg = dynamic(() =>
    import("./components/button-loading-svg.component").then(
        (icon) => icon.ButtonLoadingSvg,
    ),
);
const CalendarSvg = dynamic(() =>
    import("./components/calendar-svg.component").then(
        (icon) => icon.CalendarSvg,
    ),
);
const ChartSvg = dynamic(() =>
    import("./components/chart-svg.component").then((icon) => icon.ChartSvg),
);
const CheckedStepIconSvg = dynamic(() =>
    import("./components/checked-step-icon-svg.component").then(
        (icon) => icon.CheckedStepIconSvg,
    ),
);
const ClearLightIconSvg = dynamic(() =>
    import("./components/clear-light-svg.component").then(
        (icon) => icon.ClearLightIconSvg,
    ),
);
const XIconSvg = dynamic(() =>
    import("./components/x-svg.component").then((icon) => icon.XIconSvg),
);
const CloseNotificationModalSvg = dynamic(() =>
    import("./components/close-notification-modal-svg.component").then(
        (icon) => icon.CloseNotificationModalSvg,
    ),
);
const CloseSvg = dynamic(() =>
    import("./components/close-svg.component").then((icon) => icon.CloseSvg),
);
const CompanyDefaultLogoSvg = dynamic(() =>
    import("./components/company-default-logo-svg.component").then(
        (icon) => icon.CompanyDefaultLogoSvg,
    ),
);
const ConfigurationInfoSvg = dynamic(() =>
    import("./components/configuration-info-svg.component").then(
        (icon) => icon.ConfigurationInfoSvg,
    ),
);
const CopyIconSvg = dynamic(() =>
    import("./components/copy-icon-svg.component").then(
        (icon) => icon.CopyIconSvg,
    ),
);
const DashboardSvg = dynamic(() =>
    import("./components/dashboard-svg.component").then(
        (icon) => icon.DashboardSvg,
    ),
);
const DefaultAvatarSvg = dynamic(() =>
    import("./components/default-avatar-svg.component").then(
        (icon) => icon.DefaultAvatarSvg,
    ),
);
const DefaultGameImageSvg = dynamic(() =>
    import("./components/default-game-image-svg.component").then(
        (icon) => icon.DefaultGameImageSvg,
    ),
);
const DefaultGameSvg = dynamic(() =>
    import("./components/default-game-svg.component").then(
        (icon) => icon.DefaultGameSvg,
    ),
);
const DefaultImageIconSvg = dynamic(() =>
    import("./components/default-image-icon-svg.component").then(
        (icon) => icon.DefaultImageIconSvg,
    ),
);
const DefaultProfileInfoAvatarSvg = dynamic(() =>
    import("./components/default-profile-info-avatar-svg.component").then(
        (icon) => icon.DefaultProfileInfoAvatarSvg,
    ),
);
const DownArrowSvg = dynamic(() =>
    import("./components/down-arrow-svg.component").then(
        (icon) => icon.DownArrowSvg,
    ),
);
const DragAndDropSvg = dynamic(() =>
    import("./components/drag-and-drop-svg.component").then(
        (icon) => icon.DragAndDropSvg,
    ),
);
const ErrorSvg = dynamic(() =>
    import("./components/error-svg.component").then((icon) => icon.ErrorSvg),
);
const EyeOffSvg = dynamic(() =>
    import("./components/eye-off-svg.component").then((icon) => icon.EyeOffSvg),
);
const EyeOnSvg = dynamic(() =>
    import("./components/eye-on-svg.component").then((icon) => icon.EyeOnSvg),
);
const FilterClearIconSvg = dynamic(() =>
    import("./components/filter-clear-icon-svg.component").then(
        (icon) => icon.FilterClearIconSvg,
    ),
);
const FilterIconSvg = dynamic(() =>
    import("./components/filter-icon-svg.component").then(
        (icon) => icon.FilterIconSvg,
    ),
);
const FilterV2Svg = dynamic(() =>
    import("./components/filter-v2-svg.component").then(
        (icon) => icon.FilterV2Svg,
    ),
);
const FolderSvg = dynamic(() =>
    import("./components/folder-svg.component").then((icon) => icon.FolderSvg),
);
const GooglePlayIconSvg = dynamic(() =>
    import("./components/google-play-icon-svg.component").then(
        (icon) => icon.GooglePlayIconSvg,
    ),
);

const HeaderFilterIcon = dynamic(() =>
    import("./components/header-filter-svg.component").then(
        (icon) => icon.HeaderFilterIcon,
    ),
);
const HeaderLogoTextSvg = dynamic(() =>
    import("./components/header-logo-text-svg.component").then(
        (icon) => icon.HeaderLogoTextSvg,
    ),
);
const InfoLargeIconSvg = dynamic(() =>
    import("./components/info-large-icon-svg.component").then(
        (icon) => icon.InfoLargeIconSvg,
    ),
);
const InfoSvg = dynamic(() =>
    import("./components/info-svg.component").then((icon) => icon.InfoSvg),
);
const LeftMenuB2BManagementSvg = dynamic(() =>
    import("./components/left-menu-b2b-management-svg.component").then(
        (icon) => icon.LeftMenuB2BManagementSvg,
    ),
);
const LeftMenuCompanyManagementSvg = dynamic(() =>
    import("./components/left-menu-company-management-svg.component").then(
        (icon) => icon.LeftMenuCompanyManagementSvg,
    ),
);
const LeftMenuProviderManagementSvg = dynamic(() =>
    import("./components/left-menu-provider-management-svg.component").then(
        (icon) => icon.LeftMenuProviderManagementSvg,
    ),
);
const LineMenuSvg = dynamic(() =>
    import("./components/line-menu-svg.component").then(
        (icon) => icon.LineMenuSvg,
    ),
);
const LoaderSvg = dynamic(() =>
    import("./components/loader-svg.component").then((icon) => icon.LoaderSvg),
);
const LockSvg = dynamic(() =>
    import("./components/lock-svg.component").then((icon) => icon.LockSvg),
);
const MenuSvg = dynamic(() =>
    import("./components/menu-svg.component").then((icon) => icon.MenuSvg),
);
const NotificationsSvg = dynamic(() =>
    import("./components/notifications-svg.component").then(
        (icon) => icon.NotificationsSvg,
    ),
);
const ProfileSvg = dynamic(() =>
    import("./components/profile-svg.component").then(
        (icon) => icon.ProfileSvg,
    ),
);
const QrAppStoreSvg = dynamic(() =>
    import("./components/qr-app-store-svg.component").then(
        (icon) => icon.QrAppStoreSvg,
    ),
);
const QrGooglePlaySvg = dynamic(() =>
    import("./components/qr-google-play-svg.component").then(
        (icon) => icon.QrGooglePlaySvg,
    ),
);
const ReportSvg = dynamic(() =>
    import("./components/report-svg.component").then((icon) => icon.ReportSvg),
);
const ReverseArrowsSvg = dynamic(() =>
    import("./components/reverse-arrows-svg.component").then(
        (icon) => icon.ReverseArrowsSvg,
    ),
);
const RightArrowSvg = dynamic(() =>
    import("./components/right-arrow-svg.component").then(
        (icon) => icon.RightArrowSvg,
    ),
);
const ToggleMenuLeftArrowIconSvg = dynamic(() =>
    import("./components/toggle-menu-left-arrow-svg.component").then(
        (icon) => icon.ToggleMenuLeftArrowIconSvg,
    ),
);
const ToggleMenuRightArrowIconSvg = dynamic(() =>
    import("./components/toggle-menu-right-arrow-svg.component").then(
        (icon) => icon.ToggleMenuRightArrowIconSvg,
    ),
);
const UpArrowSvg = dynamic(() =>
    import("./components/up-arrow-svg.component").then(
        (icon) => icon.UpArrowSvg,
    ),
);
const USDSvg = dynamic(() =>
    import("./components/USD-svg.component").then((icon) => icon.USDSvg),
);
const WarningIconSvg = dynamic(() =>
    import("./components/warning-icon-svg.component").then(
        (icon) => icon.WarningIconSvg,
    ),
);
const WarningYellowIconSvg = dynamic(() =>
    import("./components/warning-yellow-icon-svg.component").then(
        (icon) => icon.WarningYellowIconSvg,
    ),
);
const ActiveStatusOnSvg = dynamic(() =>
    import("./components/active-status-on-svg.component").then(
        (icon) => icon.ActiveStatusOnSvg,
    ),
);
const ActiveStatusOffSvg = dynamic(() =>
    import("./components/active-status-off-svg.component").then(
        (icon) => icon.ActiveStatusOffSvg,
    ),
);
const SearchSvg = dynamic(() =>
    import("./components/search-svg.component").then((icon) => icon.SearchSvg),
);
const AggAnalyticsSvg = dynamic(() =>
    import("./components/agg-analytics-svg.component").then(
        (icon) => icon.AggAnalyticsSvg,
    ),
);
const TagsForGamesSvg = dynamic(() =>
    import("./components/tags-for-games-svg.component").then(
        (icon) => icon.TagsForGamesSvg,
    ),
);
const ReverseSvg = dynamic(() =>
    import("./components/reverse-svg.component").then(
        (icon) => icon.ReverseSvg,
    ),
);
const InfoIconSvg = dynamic(() =>
    import("./components/info-icon-svg.component").then(
        (icon) => icon.InfoIconSvg,
    ),
);
const ReportIconSvg = dynamic(() =>
    import("./components/report-icon-svg.component").then(
        (icon) => icon.ReportIconSvg,
    ),
);
const StandardReportWarningSvg = dynamic(() =>
    import("./components/standard-report-warning-svg.component").then(
        (icon) => icon.StandardReportWarningSvg,
    ),
);
const ProjectSvg = dynamic(() =>
    import("./components/project-svg.component").then(
        (icon) => icon.ProjectSvg,
    ),
);
const EagleSvg = dynamic(() =>
    import("./components/eagle-svg.component").then((icon) => icon.EagleSvg),
);
const MonitoringSvg = dynamic(() =>
    import("./components/monitoring-svg.component").then(
        (icon) => icon.MonitoringSvg,
    ),
);
const AddMoreSvg = dynamic(() =>
    import("./components/add-more-svg.component").then(
        (icon) => icon.AddMoreSvg,
    ),
);
const HelpSvg = dynamic(() =>
    import("./components/help-svg.component").then((icon) => icon.HelpSvg),
);

const PageInfoSvg = dynamic(() =>
    import("./components/page-info-svg.component").then(
        (icon) => icon.PageInfoSvg,
    ),
);

const MetricsForAnalyticsSvg = dynamic(() =>
    import("./components/metrics-for-analytics-svg.component").then(
        (icon) => icon.MetricsForAnalyticsSvg,
    ),
);

const UploadFileSvg = dynamic(() =>
    import("./components/upload-file-svg.component").then(
        (icon) => icon.UploadFileSvg,
    ),
);

const DefaultArticleImageSvg = dynamic(() =>
    import("./components/default-article-image-svg.component").then(
        (icon) => icon.DefaultArticleImageSvg,
    ),
);

const XlsFileSvg = dynamic(() =>
    import("./components/xls-file-svg.component").then(
        (icon) => icon.XlsFileSvg,
    ),
);

export const IconsList = {
    [Icons.warningEmotionLessIcon]: <WarningEmotionLessIcon />,
    [Icons.accessOffSvg]: <AccessOffSvg />,
    [Icons.accessOnSvg]: <AccessOnSvg />,
    [Icons.appStoreIconSvg]: <AppStoreIconSvg />,
    [Icons.authenticationIconSvg]: <AuthenticationIconSvg />,
    [Icons.authorizationLogoSvg]: <AuthorizationLogoSvg />,
    [Icons.buttonLoadingSvg]: <ButtonLoadingSvg />,
    [Icons.calendarSvg]: <CalendarSvg />,
    [Icons.chartSvg]: <ChartSvg />,
    [Icons.checkedStepIconSvg]: <CheckedStepIconSvg />,
    [Icons.clearLightSvg]: <ClearLightIconSvg />,
    [Icons.xSvg]: <XIconSvg />,
    [Icons.closeNotificationModalSvg]: <CloseNotificationModalSvg />,
    [Icons.closeSvg]: <CloseSvg />,
    [Icons.companyDefaultLogoSvg]: <CompanyDefaultLogoSvg />,
    [Icons.configurationInfoSvg]: <ConfigurationInfoSvg />,
    [Icons.copyIconSvg]: <CopyIconSvg />,
    [Icons.dashboardSvg]: <DashboardSvg />,
    [Icons.defaultAvatarSvg]: <DefaultAvatarSvg />,
    [Icons.defaultGameImageSvg]: <DefaultGameImageSvg />,
    [Icons.defaultGameSvg]: <DefaultGameSvg />,
    [Icons.defaultImageIconSvg]: <DefaultImageIconSvg />,
    [Icons.defaultProfileInfoAvatarSvg]: <DefaultProfileInfoAvatarSvg />,
    [Icons.downArrowSvg]: <DownArrowSvg />,
    [Icons.dragAndDropSvg]: <DragAndDropSvg />,
    [Icons.errorSvg]: <ErrorSvg />,
    [Icons.eyeOffSvg]: <EyeOffSvg />,
    [Icons.eyeOnSvg]: <EyeOnSvg />,
    [Icons.filterClearIconSvg]: <FilterClearIconSvg />,
    [Icons.filterIconSvg]: <FilterIconSvg />,
    [Icons.filterV2Svg]: <FilterV2Svg />,
    [Icons.folderSvg]: <FolderSvg />,
    [Icons.googlePlayIconSvg]: <GooglePlayIconSvg />,
    [Icons.headerFilterSvg]: <HeaderFilterIcon />,
    [Icons.headerLogoTextSvg]: <HeaderLogoTextSvg />,
    [Icons.infoLargeIconSvg]: <InfoLargeIconSvg />,
    [Icons.infoSvg]: <InfoSvg />,
    [Icons.leftMenuB2bManagementSvg]: <LeftMenuB2BManagementSvg />,
    [Icons.leftMenuCompanyManagementSvg]: <LeftMenuCompanyManagementSvg />,
    [Icons.leftMenuProviderManagementSvg]: <LeftMenuProviderManagementSvg />,
    [Icons.lineMenuSvg]: <LineMenuSvg />,
    [Icons.loaderSvg]: <LoaderSvg />,
    [Icons.lockSvg]: <LockSvg />,
    [Icons.menuSvg]: <MenuSvg />,
    [Icons.notificationsSvg]: <NotificationsSvg />,
    [Icons.profileSvg]: <ProfileSvg />,
    [Icons.qrAppStoreSvg]: <QrAppStoreSvg />,
    [Icons.qrGooglePlaySvg]: <QrGooglePlaySvg />,
    [Icons.reportSvg]: <ReportSvg />,
    [Icons.reverseArrowsSvg]: <ReverseArrowsSvg />,
    [Icons.rightArrowSvg]: <RightArrowSvg />,
    [Icons.toggleMenuLeftArrowSvg]: <ToggleMenuLeftArrowIconSvg />,
    [Icons.toggleMenuRightArrowSvg]: <ToggleMenuRightArrowIconSvg />,
    [Icons.upArrowSvg]: <UpArrowSvg />,
    [Icons.USDSvg]: <USDSvg />,
    [Icons.warningIconSvg]: <WarningIconSvg />,
    [Icons.warningYellowIconSvg]: <WarningYellowIconSvg />,
    [Icons.activeStatusOnSvg]: <ActiveStatusOnSvg />,
    [Icons.activeStatusOffSvg]: <ActiveStatusOffSvg />,
    [Icons.searchSvg]: <SearchSvg />,
    [Icons.tagsForGamesSvg]: <TagsForGamesSvg />,
    [Icons.aggAnalyticsSvg]: <AggAnalyticsSvg />,
    [Icons.reverseSvg]: <ReverseSvg />,
    [Icons.infoIconSvg]: <InfoIconSvg />,
    [Icons.reportIconSvg]: <ReportIconSvg />,
    [Icons.standardReportWarningSvg]: <StandardReportWarningSvg />,
    [Icons.projectSvg]: <ProjectSvg />,
    [Icons.eagleSvg]: <EagleSvg />,
    [Icons.monitoringSvg]: <MonitoringSvg />,
    [Icons.addMoreSvg]: <AddMoreSvg />,
    [Icons.pageInfoSvg]: <PageInfoSvg />,
    [Icons.helpSvg]: <HelpSvg />,
    [Icons.metricsForAnalyticsSvg]: <MetricsForAnalyticsSvg />,
    [Icons.uploadFileSvg]: <UploadFileSvg />,
    [Icons.defaultArticleImageSvg] : <DefaultArticleImageSvg />,
    [Icons.xlsFileSvg]: <XlsFileSvg />,
};

export const Icon = ({ name }: IconProps) => {
    return IconsList[name];
};
